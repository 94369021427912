@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700;900&display=swap');

body {
    margin: 0;
}

.form {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /*background: white;*/
    padding: 20px;
}

.form .form__custom-button {
    margin-top: 50px;
}

.login {
    position: absolute;
    width: 70%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 30px;
}

.closed-true {
    background: rgba(255, 26, 26, 0.13) !important;
    color: #e70606 !important;
}

.status-Cancelled {
    background: rgba(159, 159, 159, 0.13);
    color: #888888;
}

.status-Confirmed {
    background: rgba(33, 187, 0, 0.34);
    color: #2aa81b;
}

.detail-table {
    width: 100%;
    border-collapse: collapse;
}

.table-row {
    border-bottom: 1px solid #4b4b4b;
}

/*body {*/
/*    --color-background: rgb(51, 51, 51);*/
/*    --color-on-background: #fff;*/

/*    --color-tooltip: rgba(0, 5, 11, 0.9);*/
/*    --color-on-tooltip: #fff;*/

/*    --color-handle-fill: #2c343a;*/
/*    --color-handle-stroke: #67c2e4;*/
/*    --color-handle-drag-fill: transparent;*/
/*    --color-handle-dots: #67c2e4;*/
/*    --color-handle-line: #67c2e4;*/
/*}*/

/*.white-content {*/
/*    background: #e4e4e4;*/
/*}*/

.menu-item {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 1000;
    color: gray;
}

/*.menu-item:hover {*/
/*    color: #e4e4e4;*/
/*}*/

.color-Red {
    background: #ff3c3c;
}

.color-Grey {
    background: gray;
}
.color-Grey#2 {
    background: #4b4b4b;
}

.color-Purple {
    background: purple;
}

@media print {
    body, html, #wrapper {
        width: 100%;
    }
}


.messageVariable {
    background: lightgreen !important;
    padding: 10px;
    border-radius: 10px;
}

.email-wrapper {
    background: white;
    color: black;
    margin-bottom: 10px;
    border-radius: 2px;
}

.qr-reader {
    width: 430px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
}